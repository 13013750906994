/*
 * Holvi animations
 */

.holvi-animation-slideopen{
  @include transition(all 0.5s $holvi-transition-ease-in-out-circ);
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;

  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);

  max-height: 0;
  overflow: hidden;
  opacity: 0;
  &.open{
    max-height: 200rem;
    opacity: 1;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}
