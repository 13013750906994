/*
 * Additional styling to alerts
 */

.alert-link,
.alert-action {
  @include text-nav;
}

.alert-content{
  display: flex;
  justify-content: space-between;
}

.close{
  &+.alert-content{
    padding-right: $hv-spacing-6;
  }
}

.alert-action{
  margin-left: $hv-spacing-3;
}

.alert {
  display: flex;
  align-items: center;
  box-shadow: $toast-box-shadow;
  a {
    font-weight: $font-weight-bold;
    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
  }
  .close {
    margin-left: auto;
    color: inherit;
    text-shadow: none;
    opacity: 1;
  }
  > span.icon, .alert-icon {
    margin-right: $hv-spacing-2;
    font-size: $hv-icon-font-size-xl;
    line-height: 1;
  }
}

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    background-color: $white;
    border-color: $white;
    color: $body-color;
    span.icon {
      color: $value;
    }
  }
}
