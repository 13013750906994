$hv-spacer: 0.5rem;

$hv-spacing-0_5: $hv-spacer * 0.5; // 0.25rem

$hv-spacing-1: $hv-spacer * 1; // 0.5rem
$hv-spacing-1_5: $hv-spacer * 1.5; // 0.75rem

$hv-spacing-2: $hv-spacer * 2; // 1rem
$hv-spacing-2_5: $hv-spacer * 2.5;  // 1.25rem

$hv-spacing-3: $hv-spacer * 3;  // 1.5rem
$hv-spacing-3_5: $hv-spacer * 3.5; // 1.75rem

$hv-spacing-4: $hv-spacer * 4; // 2rem
$hv-spacing-4_5: $hv-spacer * 4.5; //2.25rem

$hv-spacing-5: $hv-spacer * 5; // 2.5rem
$hv-spacing-5_5: $hv-spacer * 5.5; // 2.75rem

$hv-spacing-6: $hv-spacer * 6; // 3rem
$hv-spacing-6_5: $hv-spacer * 6.5; // 3.25rem

$hv-spacing-7: $hv-spacer * 7; // 3.5rem
$hv-spacing-7_5: $hv-spacer * 7.5; // 3.75rem

$hv-spacing-8: $hv-spacer * 8; // 4rem
$hv-spacing-8_5: $hv-spacer * 8.5; // 4.25rem

$hv-spacing-9: $hv-spacer * 9; // 4.5rem
$hv-spacing-9_5: $hv-spacer * 9.5; // 4.75rem

$hv-spacing-10: $hv-spacer * 10; // 5rem
$hv-spacing-10_5: $hv-spacer * 10.5; // 5.25rem

$hv-spacing-11: $hv-spacer * 11; // 5.5rem
$hv-spacing-11_5: $hv-spacer * 11.5; // 5.75rem

$hv-spacing-12: $hv-spacer * 12; // 6rem
$hv-spacing-12_5: $hv-spacer * 12.5; // 6.25rem
