$switcher-width: 3.5rem;
$switcher-height: 2rem;
$switcher-width_sm: 2.25rem;
$switcher-height_sm: 1.25rem;
$switcher-border-radius: 18px;

$switcher-slider-handle-width: 1.5rem;
$switcher-slider-handle-height: 1.5rem;
$switcher-slider-handle-width_sm: 1rem;
$switcher-slider-handle-height_sm: 1rem;
$switcher-slider-gap: 0.25rem;
$switcher-slider-gap_sm: 0.125rem;

$switcher-transform: 1.5rem;
$switcher-transform_sm: 1rem;

.switcher-container {
  display: flex;
  align-items: center;
  margin-bottom: $hv-spacing-1;

  .switcher {
    position: relative;
    display: inline-block;
    width: $switcher-width;
    height: $switcher-height;

    &__checkbox {
      cursor: pointer;
      appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      z-index: 1;
      outline: 0 none;
      border-radius: $switcher-border-radius;

      &:checked + .switcher__slider:before {
        transform: translateX($switcher-transform);
        background-color: $hv-white;
      }

      &:checked + .switcher__slider {
        background-color: $hv-primary;
      }

      &:disabled:not(:checked) + .switcher__slider {
        cursor: initial;
        background-color: $hv-gray-300;
      }
      &:disabled:not(:checked) + .switcher__slider:before {
        background-color: $hv-gray-600;
      }
      &:disabled:is(:checked) + .switcher__slider {
        cursor: initial;
        background-color: $hv-primary-light;
      }
      &:disabled:is(:checked) + .switcher__slider:before {
        background-color: $hv-gray-400;
      }
    }
    &__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $hv-gray-600;
      transition: 0.4s;
      border-radius: $switcher-border-radius;

      &:before {
        position: absolute;
        content: '';
        height: $switcher-slider-handle-height;
        width: $switcher-slider-handle-width;
        left: $switcher-slider-gap;
        bottom: $switcher-slider-gap;
        background-color: $hv-white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    &__label {
      padding: 0 0 0 $hv-spacing-1;
      font-weight: $hv-font-weight-normal;
      font-size: $hv-font-size-base;
    }
  }

  &:has(.switcher_sm) {
    .switcher {
      width: $switcher-width_sm;
      height: $switcher-height_sm;

      &__checkbox {
        &:checked + .switcher__slider:before {
          transform: translateX($switcher-transform_sm);
        }
      }

      &__slider {
        &:before {
          height: $switcher-slider-handle-height_sm;
          width: $switcher-slider-handle-width_sm;
          left: $switcher-slider-gap_sm;
          bottom: $switcher-slider-gap_sm;
        }
      }
    }
  }
}
