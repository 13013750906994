/*
 * Additional styling to nav
 */

.nav-tabs {
  .nav-link {
    color: inherit;
    border-bottom-color: $gray-600;

    @include hover() {
      margin-top: $nav-tabs-border-width;
      margin-left: $nav-tabs-border-width;
      margin-right: $nav-tabs-border-width;
      border-top: none;
      border-left: none;
      border-right: none;
    }

    &.active {
      font-weight: $hv-font-weight-semi-bold;
      margin-top: $nav-tabs-border-width;
      margin-left: $nav-tabs-border-width;
      margin-right: $nav-tabs-border-width;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}

.nav-pills {
  .nav-item {
    .nav-link {
      padding: $hv-spacing-1 $hv-spacing-2;
      color: $hv-gray-800;

      @include text-nav;
      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }

      &:hover {
        color: $hv-gray-900;
        text-decoration: none;
      }

      &.active {
        color: $hv-gray-900;
        background-color: initial;
      }
    }

    &.dropdown {
      &.show,
      &.active {
        & > .nav-link {
          color: $hv-gray-900;
          background-color: initial;
        }
      }
    }
  }
  .nav-item:first-of-type {
    .nav-link {
      padding-left: 0;
      text-align: left;
    }
  }
  li.navbar-text {
    @include text-nav;

    padding: 0.5rem;
    color: $hv-gray-800;
    cursor: initial;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: $hv-spacing-2;
    }
  }
}

