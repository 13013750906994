$carousel-control-size: 2.25rem;
$carousel-control-background-size: 1rem;
$carousel-control-border-radius: 6px;
$carousel-dot-size: 0.5rem;

@mixin carousel-control {
  background-color: $hv-gray-300;
  width: $carousel-control-size;
  height: $carousel-control-size;
  background-size: $carousel-control-background-size;
  border-radius: $carousel-control-border-radius;

  &:hover {
    border-color: $hv-gray-400;
  }
}

.carousel {
  width: 100%;
  height: 100%;
  padding-top: $hv-spacing-6;
  padding-bottom: $hv-spacing-6;

  &-inner {
    height: 100%;
  }

  &-item {
    height: 100%;

    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &-indicators li {
    width: $carousel-dot-size;
    height: $carousel-dot-size;
    border-radius: $hv-border-radius-circle;
    background-color: $hv-gray-400;
    margin-left: $hv-spacing-1;
    margin-right: $hv-spacing-1;

    &.active {
      background-color: $hv-primary;
    }

  }

  .carousel-control-next-icon, .carousel-control-prev-icon {
      @include carousel-control;
  }
}
