/*
 * This file should be moved to devtools when all vault
 * pages are in appenzeller. For now this styling is in holvitwbs
 * so that vault can also us it for that login page, but makes more
 * sense to have it in devtools because this is not bootstrap components
 */

.layout-container{
  margin: 0;
  @include media-breakpoint-up(md){
    margin: 5rem auto;
  }
  @include media-breakpoint-up(lg){
    margin: 10rem auto;
  }
}

.background-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

body,.background-wrapper {
  &.background-blue{
    .layout-container{
        z-index: 1000;
    }
    .background-container{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      z-index: -100;

      background-color: hsla(205,100%,50%,1);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;

      &:before,
      &:after{
        width: 5rem;
        height: 5rem;
        opacity: 0;
        border-radius: 5rem;

        position: absolute;
        left: 50%;
        bottom: 0;
        margin-bottom: 50rem;
        transform-origin: 50%, 5000%;

        content: "";
        display: block;
      }
    }
  }
}

.page-content{
  background: hsla(0, 100%, 100%, 0.95);
  border: 0.1rem solid $gray-400;
  padding: 2.9rem $hv-spacing-6 $hv-spacing-6;
  margin-top: $hv-spacing-3;
  margin-bottom: $hv-spacing-3;

	.logo{
	  height: 3rem;
	}
}

.landscape-logo{
  height: 1.5rem;
}

.login-language-switcher{
  margin-top: 0.4rem;
}

.additional-info-description{
  margin-top: $hv-spacing-4;
}
