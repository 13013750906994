$hv-gray-900: #1d1d1c;
$hv-gray-800: #575a61;
$hv-gray-700: #8d969d;
$hv-gray-600: #c4d5d9;
$hv-gray-500: #d7e3e6;
$hv-gray-400: #dee9eb;
$hv-gray-300: #eef4f6;
$hv-gray-200: #f7f9fa;
$hv-gray-150: #fbfcfd;
$hv-gray-100: #ffffff;

$hv-primary: #0c8487;
$hv-danger: #FA0000;
$hv-success: #0E893E;
$hv-warning: #FFAB00;
$hv-info: #226EF9;
$hv-white: #fff;
$hv-pink: #ffbe9e;

$hv-primary-light: #69d1cc;
$hv-success-light: #E8FCEA;
$hv-warning-light: #FFF8EB;
$hv-danger-light: #FDEDED;
$hv-info-light: #EDF3FD;

$hv-success-dark: #006125;
$hv-warning-dark: #754C00;
$hv-danger-dark: #A30000;
$hv-info-dark: #0041C2;

$hv-brand-primary-1: #FDF86E;
$hv-brand-primary-2: #FFBD9C;
$hv-brand-primary-3: #F06E84;
$hv-brand-primary-4: #E895C3;
$hv-brand-primary-5: #BBE5EE;
$hv-brand-primary-6: #69D1CC;

$hv-brand-secondary-1: #FFBF00;
$hv-brand-secondary-2: #FF5F0E;
$hv-brand-secondary-3: #D71C5B;
$hv-brand-secondary-4: #8E348B;
$hv-brand-secondary-5: #385BAE;
$hv-brand-secondary-6: #064545;

$hv-brand-neutral-1: #23282D;
$hv-brand-neutral-2: #C4D5D9;
$hv-brand-neutral-3: #D0DDE1;
$hv-brand-neutral-4: #DCE6E8;
$hv-brand-neutral-5: #e8edf0;
$hv-brand-neutral-6: #f2f7f7;
