/*
 * Styles for showing media
 */

.media.media-with-icon {
  align-items: center;
  .icon {
    font-size: $hv-icon-font-size-xl;
    margin-right: $hv-spacing-2;
  }
}

