/*
 * Content-box
 * Watch out, .content-box is being used in many different places, in components in Quark, or also directly in Appenzeller!
 */

.content-box {
  background-color: $card-background-color;
  border: $hv-border-width-normal solid $card-background-color;
  border-radius: $border-radius;

  // same paddings as cards so they align nicely
  @include media-breakpoint-up(md){
    padding: $card-spacer-x;
  }
  @include media-breakpoint-down(sm){
    padding: $card-spacer-x-sm;
  }

  & + .content-box {
    margin-top: $grid-gutter-width;
  }

  // reset margin in form-footer so that there's no double spacing
  // (assuming form-footer is always the last element in content-box, as it should be)
  .form-footer {
    margin-bottom: 0;
  }
  // when form-footer is direct sister of content-box, increase margin
  & + .form-footer {
    margin-top: $grid-gutter-width;
  }

  // fallback for content-box inside a content-box, instead you should just remove the .content-box
  // inside the .content-box when possible! (this is for some deeply rooted stuff)
  .content-box {
    padding: 0;
  }
}

// these classes should help if you have two content-boxes which
// are not sister elements but need the spacing.
// (horizontal spacing should just be done with row/col system)
.content-box-mt {
  margin-top: $grid-gutter-width;
}

.content-box-mb {
  margin-bottom: $grid-gutter-width;
}

$spacing-for-outline: 1px;
.content-box-selectable, a.content-box {
  &:hover, &:focus {
    cursor: pointer;
    background-color: $card-background-color;
    border-color: $card-border-color-hover;
  }
}

// These overrides are needed to make the hover state of 'a' tag to have no underline and work with row/col.
a.content-box {
  display: block;
  text-decoration: none !important;
  color: inherit;
}
