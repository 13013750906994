/*
 * Additional styling to toasts
 */

.toast-header {
  border-bottom-color: $toast-header-border-color;
  button.close {
    margin-top: -$hv-spacing-0_5;
    text-shadow: none;
    opacity: 1;
  }
  &::before {
    font-family: $icon-font-family;
    content: '\EFA0'; // holvi-fonticons: icon-info-full
    margin-right: $hv-spacing-1;
    color: $info;
    font-size: $hv-icon-font-size-lg;
  }
}

.b-toast-success {
  .toast-header::before {
    content: '\EFA1'; // holvi-fonticons: icon-success-full
  }
}

.b-toast-warning {
  .toast-header::before {
    content: '\EFA2'; // holvi-fonticons: icon-alert-full
  }
}

.b-toast-danger {
  .toast-header::before {
    content: '\EFA4'; // holvi-fonticons:
  }
}

@each $color, $value in $theme-colors {
  .b-toast-#{$color} {
    .toast {
      color: $body-color;
      background-color: $white;
      border-color: $toast-border-color;
      .toast-header {
        color: $body-color;
        background-color: $white;
        border-bottom-color: $toast-header-border-color;
        &::before {
          color: $value;
        }
      }
    }
  }
}
