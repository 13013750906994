/*
 * File for Holvi specific mixins
 */

// So that we can loop through non-bootstrap colours that we added ourselves
$holvi-colors: (
  success: (
    dark: $success-dark,
    base: $success,
    light: $success-light
  ),
  warning: (
    dark: $warning-dark,
    base: $warning,
    light: $warning-light
  ),
  danger: (
    dark: $danger-dark,
    base: $danger,
    light: $danger-light
  ),
  info: (
    dark: $info-dark,
    base: $info,
    light: $info-light
  )
);

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin text-nav {
  font-weight: $nav-font-weight;
  font-family: $nav-font-family;
  font-size: $nav-font-size;
  text-transform: $nav-text-transform;
  cursor: pointer;
}

.text-nav {
  @include text-nav;
}
