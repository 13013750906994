.b-calendar {
  // undo some custom form-control styles that look weird in the calendar
  .form-control {
    border: $hv-border-width-none;
    background: $hv-white;
    box-shadow: none;
  }

  .b-calendar-nav {
    padding: $hv-spacing-2;
    background-color: $hv-gray-150;
    border-bottom: 1px solid $hv-gray-300;
    margin-bottom: 0;

    // make calendar controls a nicer colour, the bv component has no props for this atm
    .btn {
      color: $hv-gray-900;
      &:hover {
        background-color: transparent;
        opacity: 0.5;
      }
    }
  }

  .b-calendar-grid {
    padding: $hv-spacing-2;
    &-caption {
      padding-bottom: $hv-spacing-1;
      font-size: $hv-font-size-lg;
    }
    &-weekdays {
      border-bottom: 1px solid $hv-gray-300 !important;
      line-height: 32px;
      color: $hv-gray-800;
    }
    &-body {
      .col[data-date] .btn {
        width: 32px;
        height: 32px;
        font-size: 14px;
        line-height: 1;
        margin: 3px auto;
        padding: 9px 0;
      }
      .row {
        [role='button'] {
          background-color: $hv-white !important;
          color: $hv-gray-900 !important;

          span {
            font-weight: $hv-font-weight-normal !important;
            &.btn-outline-secondary {
              background-color: $hv-gray-200 !important;
              color: $hv-gray-900 !important;
            }
            &:not(:disabled):not(.disabled).active {
              background-color: $hv-info !important;
              &:hover {
                background-color: $hv-info-light !important;
                color: $hv-gray-900 !important;
              }
            }
            &.disabled {
              color: $hv-gray-600 !important;
              &:hover {
                color: $hv-gray-600 !important;
                background-color: $hv-white !important;
              }
            }
            &:hover {
              background-color: $hv-info-light !important;
              color: $hv-gray-900 !important;
            }
          }
        }
      }
    }
  }

  .border-top {
    border: 0 !important;
  }

  .btn {
    cursor: pointer;
  }

  .no-uppercase {
    text-transform: none;
  }
}
