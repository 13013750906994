@use "sass:color";
@use "sass:math";

/*
 * Bootstrap variable overwrites, only add variables here that are used by Bootstrap
 */

$gray-900: $hv-gray-900; // text black
$gray-800: $hv-gray-800;
$gray-700: $hv-gray-700;
$gray-600: $hv-gray-600; // secondary grey
$gray-500: $hv-gray-500;
$gray-400: $hv-gray-400; // select grey
$gray-300: $hv-gray-300;
$gray-200: $hv-gray-200; // off white
$gray-100: $hv-gray-100; // white

$primary: $hv-primary;
$secondary: $gray-600;
$danger: $hv-danger;
$success: $hv-success;
$warning: $hv-warning;
$info: $hv-info;
$light: $gray-300;
$white: $hv-white;
$black: $gray-900;
$dark: $black;

$red: $danger;
$orange: $info;
$yellow: $warning;
$cyan: $primary;
$blue: $info;
$green: $success;
$indigo: $primary;
$purple: $primary;
$pink: $hv-pink;
$teal: $green;

// custom light versions of primary colours, NOT Bootstrap variables
$primary-light: $hv-primary-light;
$success-light: $hv-success-light;
$warning-light: $hv-warning-light;
$danger-light: $hv-danger-light;
$info-light: $hv-info-light;

// custom dark versions of primary colours, NOT Bootstrap variables
$success-dark: $hv-success-dark;
$warning-dark: $hv-warning-dark;
$danger-dark: $hv-danger-dark;
$info-dark: $hv-info-dark;

// theme colors
$theme-colors: (
  "secondary-light": $hv-gray-200
);

// FONT
$font-weight-normal: $hv-font-weight-normal;
$font-family-monospace: $hv-font-family-monospace;
$font-family-sans-serif: $hv-font-family-sans-serif;
$font-size-base: $hv-font-size-base;
$font-size-lg: $hv-font-size-lg;
$font-size-sm: $hv-font-size-sm;
$font-weight-bold: $hv-font-weight-semi-bold;
$text-muted: $gray-800;
$text-danger: $danger-dark;
$small-font-size: $hv-small-font-size;
$lead-font-size: $font-size-lg;
$lead-font-weight: $font-weight-normal;

// HEADINGS
$h1-font-size: $hv-h1-font-size;
$h1-mobile-font-size: $hv-h1-mobile-font-size;
$h2-font-size: $hv-h2-font-size;
$h3-font-size: $hv-h3-font-size;
$h4-font-size: $hv-h4-font-size;
$h5-font-size: $hv-h5-font-size;
$h6-font-size: $hv-h6-font-size;
$headings-color: $dark;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: $hv-headings-font-weight;
$headings-line-height: $hv-headings-line-height;
$headings-margin-bottom: $hv-spacing-1_5;

// BORDER
$body-color: $black;
$border-color: $gray-600;
$border-radius: $hv-border-radius;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;
$border-width: $hv-border-width-thick;

// INPUT
$input-border-color: $border-color;
$input-border-width: $hv-border-width-normal;
$input-height-border: $input-border-width * 4;

$input-btn-focus-color: rgba($primary, .25);
$input-btn-focus-width: 0.1rem;
$input-btn-padding-x-lg: $hv-spacing-5;
$input-btn-padding-x-sm: $hv-spacing-1_5;
$input-btn-padding-x: $hv-spacing-2_5;
$input-btn-line-height: 1.75;

$input-color: $dark;
$input-disabled-bg: $gray-300;
$input-focus-border-color: $primary;
$input-group-addon-bg: $gray-100;

$input-padding-x-lg: $hv-spacing-2;
$input-padding-x-sm: $hv-spacing-1;
$input-padding-x: $hv-spacing-1_5;

$input-placeholder-color: $text-muted;
$input-transition: $hv-input-transition;

// BUTTON
$btn-font-weight: $font-weight-bold;

// DROPDOWN
$dropdown-border-color: $light;
$dropdown-border-width: $hv-border-width-none;
$dropdown-divider-bg: $dropdown-border-color;
$dropdown-header-color: $body-color;
$dropdown-item-padding-y: $hv-spacing-1;
$dropdown-link-active-bg: $gray-400;
$dropdown-link-active-color: $body-color;
$dropdown-link-color: $body-color;
$dropdown-link-hover-bg: $gray-300;
$dropdown-link-hover-color: $body-color;

// TOOLTIP and POPOVER
$tooltip-bg: $dark;
$tooltip-color: $white;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-opacity: 1;
$popover-bg: $tooltip-bg;
$popover-border-color: $popover-bg;
$popover-header-color: $tooltip-color;
$popover-arrow-color: $popover-bg;

// TOAST
$toast-background-color: $white;
$toast-border-color: $white;
$toast-border-radius: $border-radius;
$toast-header-background-color: $white;
$toast-box-shadow: $hv-toast-box-shadow;
$toast-padding-y: $hv-spacing-1;
$toast-header-border-color: $border-color;
$toast-header-background-color: transparent;
$toast-header-color: $body-color;

// MODAL
$modal-backdrop-opacity: .5;
$modal-content-border-color: transparent;
$modal-header-border-color: transparent;
$modal-header-padding: $hv-spacing-3 $hv-spacing-3 0;
$modal-inner-padding-y: $hv-spacing-3;
$modal-inner-padding-x: $hv-spacing-1;
$modal-inner-padding: $hv-spacing-3;
$modal-inner-min-height: 6.25rem;
$modal-footer-margin-between: $hv-spacing-2;

// OTHER
$alert-padding-y: $hv-spacing-2;
$alert-padding-x: $alert-padding-y;
$badge-font-weight: $font-weight-normal;
$badge-font-size: 0.75rem;
// TODO: do we need em here?
$badge-padding-x: 1em;
$badge-padding-y: .5em;
$badge-pill-border-radius: $hv-badge-pill-border-radius;
$badge-pill-padding-x: $badge-padding-x;
$body-bg: $gray-300;
$breadcrumb-active-color: $text-muted;
$breadcrumb-bg: rgba($dark, .025);
$breadcrumb-divider-color: $dark;
$breadcrumb-padding-y: $hv-spacing-1_5;
$btn-disabled-opacity: 0.75;
$card-background-color: $hv-gray-100;
$card-border-color-hover: $hv-gray-700;
$card-spacer-x: $hv-spacing-4;
$card-spacer-x-sm: $hv-spacing-2;
$card-spacer-y: $hv-spacing-5;
$close-font-weight: $font-weight-normal;
$custom-control-indicator-active-bg: $primary-light;
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1200px);
$custom-control-indicator-border-color: $input-border-color;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-border-color: $primary;
$custom-control-label-disabled-color: $text-muted;
$custom-select-disabled-bg: $gray-300;
$custom-select-disabled-color: $text-muted;
$display1-size: 6rem;
$display1-weight: $font-weight-bold;
$display2-size: 5rem;
$display2-weight: $font-weight-bold;
$display3-size: 4rem;
$display3-weight: $font-weight-bold;
$display4-size: 3rem;
$display4-weight: $font-weight-bold;
$figure-caption-font-size: $hv-small-font-size;
$grid-gutter-width: 2rem;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1440px);
$hr-border-color: $border-color;
$jumbotron-bg: $info;
$link-color: $primary;
$list-group-border-color: transparent;
$list-group-disabled-color: $text-muted;
$nav-link-padding-x: $hv-spacing-3;
$nav-tabs-border-color: $border-color;
$nav-tabs-link-active-bg: inherit;
$nav-tabs-link-active-color: inherit;
$nav-tabs-link-active-border-color: $primary;
$nav-tabs-link-hover-border-color: $primary;
$navbar-light-disabled-color: rgba($dark, .25);
$navbar-nav-link-padding-x: $hv-spacing-2;

// TODO probably need to be replaced with new spacing tokens
$spacer: $hv-spacing-2;
$spacers: (6: ($spacer * 4.5), 7: ($spacer * 6), 8: ($spacer * 7.5) , 9: ($spacer * 9), 10: ($spacer * 11.5));

$navbar-padding-y: $spacer;
$pagination-active-bg: $info;
$pagination-border-color: transparent;
$pagination-active-border-color: $pagination-border-color;
$pagination-active-color: $dark;
$pagination-bg: rgba($dark, .025);
$pagination-border-width: $hv-border-width-none;
$pagination-disabled-bg: $pagination-bg;
$pagination-disabled-border-color: $pagination-border-color;
$pagination-disabled-color: $text-muted;
$pagination-hover-bg: rgba($info, .5);
$pagination-hover-border-color: $pagination-border-color;
$progress-bg: $light;
$table-accent-bg: color.adjust($light, $lightness: -2.5%);
$table-border-color: color.adjust($light, $lightness: -5%);
$table-border-width:  math.div($border-width, 2);
$table-cell-padding-sm: $hv-spacing-0_5;
$table-dark-accent-bg: color.adjust($dark, $lightness: 2.5%);
$table-dark-border-color: color.adjust($dark, $lightness: 5%);
$table-dark-color: $white;
$table-dark-hover-bg: color.adjust($dark, $lightness: 5%);
$table-head-color: $body-color;
$table-hover-bg: color.adjust($light, $lightness: -5%);
$theme-color-interval: 7.5%;
$transition-collapse: $hv-transition-collapse;
$yiq-text-dark: $dark;
