/*
 * Any styles specific to button groups
 */

// use this for extra spacing between buttons
.btn-group-spacing {
  .btn+.btn:not(.btn-block) {
    margin-left: $btn-spacing;
  }
}

// you can also add class per button for more control
.btn {
  &.btn-spacing-left {
    margin-left: $btn-spacing;
  }
  &.btn-spacing-right {
    margin-right: $btn-spacing;
  }
}

.btn-group-even {
  display: flex;
  .btn {
    flex: 1;
  }
}

.btn-toolbar {
  display: flex;
  .btn {
    flex: 1;
  }
}
