.icon:before {  // NOTE: defined in holvi-fonticons as well
  font-family: $icon-font-family;
  line-height: 1;
  font-style: normal;
  font-weight: normal;
}

.icon-md:before {
  font-size: $hv-icon-font-size-md;
}

.icon-lg:before {
  font-size: $hv-icon-font-size-lg;
}

.icon-xl:before {
  font-size: $hv-icon-font-size-xl;
}

.arrow-right:before {
  content: '\203A';
}

.arrow-left:before {
  content: '\2039';
}