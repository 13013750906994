/*
 * Any styles specific to type
 */

body {
  font-variant-numeric: lining-nums tabular-nums;
}

b, strong {
  font-weight: $font-weight-bold;
}

small, .small, .smallp {
  font-size: $font-size-sm;
}

h1, .h1 {
  font-family: $secondary-font-family;
  @include media-breakpoint-down(sm){
    // smaller on mobile
    font-size: $h1-mobile-font-size;
  }
}

a {
  cursor: pointer;
}

.text-linky {
  @extend a;
  // need to do some "!important" for <a/> without href
  color: $link-color !important;
  &:hover {
    color: $link-hover-color !important;
    text-decoration: $link-hover-decoration !important;
  }
}

.thincaps {
  font-weight: $hv-font-weight-normal;
  text-transform: uppercase;
}

.tabular,
.text-tabular {
  -webkit-font-feature-settings: 'tnum';
  -moz-font-feature-settings: 'tnum';
  -ms-font-feature-settings: 'tnum';
}

// for when background color is too light
.text-color-invert {
  color: $hv-white !important;
  a, a:hover, label, .label, h1, h2, h3, h4, h5, h6 {
    color: $hv-white !important;
  }
}

.no-wrap {
  white-space: nowrap;
}

.lead, .lead p {
  font-size: $lead-font-size;
}

// overwrite bootstrap value which uses !important
.text-danger {
  color: $text-danger!important;
}
