

// for vault old style popovers
.popover{
  border: none;
  border-radius: 0;
  background-color: $popover-bg;
  opacity: 0;
  padding: 0;
  box-shadow: none;
  transition: opacity .2s $holvi-transition-ease-in-out;

	.arrow {
		display: none !important;
	}
}

.popover-title{
  padding: 1.4rem 1.4rem 0;
  @extend .h6;
  margin-bottom: -$hv-spacing-2 !important;
  border: none;
  background-color: transparent;
  opacity: 0;
  transform: translateX(-1.5rem);
  transition: transform .2s $holvi-transition-ease-in-out .15s, opacity .2s $holvi-transition-ease-in-out .15s;
}

.popover-content{
  font-family: $font-family-base;
  font-size: 1.1rem;
  padding: 1.4rem;
  transform: translateX(-1.5rem);
  opacity: 0;
  transition: transform .2s $holvi-transition-ease-in-out .25s, opacity .2s $holvi-transition-ease-in-out .25s;
}
.popover.in{
  opacity: 1;
  animation: holvi-tooltip-transition .2s $holvi-transition-ease-in-out;
  >.popover-content,
  >.popover-title{
    transform: translateX(0);
    opacity: 1;
  }
}
