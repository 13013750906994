// remove browser default focus outline
:focus{
  outline: 0 !important;
}

// helper class
.img-responsive{
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

// add this class to the transcluded item you want to hide in case its empty
.trancluded-item:empty {
  display: none;
}
