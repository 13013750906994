@use "sass:color";

/*
 * Holvi button modifications and additions
 */

.btn {
  @include text-nav;
  white-space: normal;
  &.disabled, &:disabled {
    cursor: default;
  }
  &.icon-borderless {
    &:focus, &.focus {
      box-shadow: none;
    }
    &:not(:hover) {
      border-color: transparent;
      background-color: transparent !important;
    }
    &.disabled{
      border-color: transparent;
      opacity: 0.45;
    }
  }
  &.icon:before{
    font-size: 1.25rem;
  }
  &:not(:empty):not(.input-group-text).icon:before{
    margin-right: $hv-spacing-2;
  }

  &[class*="btn-outline-"] {
    .text-color-invert &{
      border-color: $white;
      color: $white;
    }
  }
  .caret{
    margin-left: $hv-spacing-2;
  }
}

// adjust button statuses
.btn-primary {
  &:hover:not(.disabled):not(:disabled) {
    border-color: $pink;
    background-color: $pink;
    color: inherit;
    opacity: 1;
    &:focus {
      box-shadow: 0 0 0 $btn-focus-width color.adjust($pink, $alpha: 0.4);
    }
  }
  &.disabled, &:disabled {
    background-color: $primary-light;
    border-color: $primary-light;
    opacity: 1;
  }
}

.btn-outline-primary {
  &:hover:not(.disabled):not(:disabled) {
    border-color: $pink;
    background-color: inherit;
    color: $pink;
    opacity: 1;
  }
  &.disabled, &:disabled {
    border-color: $primary-light;
    opacity: 1;
  }
}

.btn-secondary {
  &:hover:not(.disabled):not(:disabled) {
    background-color: $gray-400;
    border-color: $secondary;
    opacity: 1;
  }
  &.disabled, &:disabled {
    background-color: $secondary;
    color: $white;
    opacity: 1;
  }
}

.btn-dark {
  &:hover:not(.disabled):not(:disabled) {
    background-color: $gray-800;
    border-color: $dark;
    opacity: 1;
  }
  &.disabled, &:disabled {
    background-color: $gray-700;
    border-color: $gray-700;
    opacity: 1;
  }
}

.btn-icon-only.btn-link {
  &:hover, &:focus {
    text-decoration: none;
  }
}

// classes to display icon-only in breakpoints, covers only :before not :after
// yet, update when needed
.btn-icon-only:not([class*="btn-show-text-"]) > * { @include sr-only(); }
.btn-icon-only:not([class*="btn-show-text-"]).icon:before { margin-right: 0 !important; }
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    .btn-icon-only.btn-show-text-#{$breakpoint} > * { @include sr-only(); }
    .btn-icon-only.btn-show-text-#{$breakpoint}.icon:before {
      margin-right: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.5rem;    // keep it the same height as in bigger screens
      width: 0;
    }
  }
}

// Bootstrap 3 had classes to apply btn-block to media-breakpoints, B4 removed this.
// We're using this for mobile sizes in some spots so addid this as helper.
.btn-block-md {
  @include media-breakpoint-down(sm){
    width: 100%;
  }
}

.btn-round {
  width: $btn-round-diameter;
  border-radius: $btn-round-diameter;
  height: $btn-round-diameter;
  padding: 0;
  margin: 0;
  line-height: 1;
}
