@mixin disabled() {
  &--muted {
    opacity: 0.5;
  }
}

.card {
  background-color: $card-background-color;
  border: $hv-border-width-normal solid $card-background-color;

  // same paddings as content-box so they align nicely
  padding: $card-spacer-x $card-spacer-x $card-spacer-y;

  &.selectable {
    cursor: pointer;

    &:hover {
      background-color: $card-background-color;
      border-color: $card-border-color-hover;
    }
  }

  &.selected {
    border-color: $hv-success;
  }
}

.card-header {
  background-color: unset;
  border-bottom: 0 none;
  padding: 0 0 $card-spacer-x;

  @include disabled()
}

.card-image {
  width: auto;
  height: 7rem;
  padding-bottom: $hv-spacing-1;

  @include disabled()
}

.card-body {
  padding: 0;

  @include disabled()
}

.card-title {
  margin-bottom: $card-spacer-x-sm;
}

.card-subtitle {
  margin-top: 0;
}

.card-mark {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: $hv-border-radius-circle;

  .icon {
    font-size: $hv-font-size-xl-2;
    line-height: $hv-font-size-xl-2;
    font-weight: $hv-headings-font-weight;
  }

  @include disabled()
}

.card-footer {
  background-color: unset;
  border-top: 0 none;
  padding: $hv-spacing-3 0 0;

  @include disabled()
}

// to be used on a `.row` where the `.col-*`s contain cards
// which should maintain the same height
.card-row-eq-height {
  [class^="col-"] {
    margin-bottom: $grid-gutter-width;
  }
  .card {
    height: 100%;
  }
}
