$hv-border-width-none: 0;
$hv-border-width-normal: 1px;
$hv-border-width-thick: 2px;

$hv-border-radius: 0.375rem;
$hv-badge-pill-border-radius: 1rem;

$hv-border-radius-none: 0;
$hv-border-radius-circle: 50%;

$hv-border-tiny: 0.1rem solid $hv-gray-600;
$hv-border-tiny-dotted: 0.1rem dotted $hv-gray-600;