/*
 * File for bootstrap4 vault overrides. Get rid of this file whenever all of
 * vault is migrated to bootstrap4!
 */

.border-actions.border {
  border-bottom: 0 !important;
}


// bootstrap3 class for vault
.hide {
  display: none !important;
}

.country-tooltip {
  text-decoration: underline !important;
  font-size: $h4-font-size;
}
