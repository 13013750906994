/*
 * File for additional variables, NOT Bootstrap variables!
 */

// Font
$secondary-font-family: $hv-font-family-secondary;
$icon-font-family: $hv-font-family-icon;

// Transitions
$holvi-transition-ease-in-out: $hv-transition-ease-in-out;
$holvi-transition-ease-in-out-circ: $hv-transition-ease-in-out-circ;
$holvi-transition-ease-in-out-expo: $hv-transition-ease-in-out-expo;

// Navbar
$navbar-height: 4.5rem;

// Icons
$icon-md: $hv-icon-font-size-md;
$icon-lg: $hv-icon-font-size-lg;
$icon-xl: $hv-icon-font-size-xl;
$icon-wrapper-size: 1.75rem;
$icon-wrapper-size-sm: 1.25rem;

// Forms
$input-box-shadow: $hv-input-box-shadow;
$input-focus-box-shadow: $hv-input-focus-box-shadow;

$input-box-shadow-error: $hv-input-box-shadow-error;
$input-focus-box-shadow-error: $hv-input-focus-box-shadow-error;

// Buttons
$btn-spacing: $hv-spacing-3;

// Dropdowns
$dropdown-shadow-color: rgba(0, 0, 0, 0.12);
$dropdown-shadow-h-offset: 0;
$dropdown-shadow-v-offset: 0;
$dropdown-shadow-blur: 3px;
$dropdown-shadow-spread: 2px;

// Navigational elements (buttons, nav)
$nav-link-height: 3rem;
$nav-font-weight: $hv-font-weight-bold;
$nav-font-family: $hv-font-family-sans-serif;
$nav-font-size: $hv-font-size-base;
$nav-text-transform: uppercase;
$btn-round-diameter: $input-btn-padding-x-lg; //2.5rem

// when image (e.g. logo) is placed in the navbar, use these sizes
$navbar-image-size: 2.5rem;
$navbar-image-size-sm: 1.5rem; // media-breakpoint-down(sm)

// badges
$badge-border-width: $hv-border-width-normal;
$badge-square-size: 2rem;

// carousel
$carousel-control-prev-icon-bg: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons / unmasked / 0295_angle-left %3C/title%3E%3Cdefs%3E%3Cpath d='M10.4142136,12 L15.7071068,6.70710678 C16.0976311,6.31658249 16.0976311,5.68341751 15.7071068,5.29289322 C15.3165825,4.90236893 14.6834175,4.90236893 14.2928932,5.29289322 L8.29289322,11.2928932 C7.90236893,11.6834175 7.90236893,12.3165825 8.29289322,12.7071068 L14.2928932,18.7071068 C14.6834175,19.0976311 15.3165825,19.0976311 15.7071068,18.7071068 C16.0976311,18.3165825 16.0976311,17.6834175 15.7071068,17.2928932 L10.4142136,12 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Icons-/-unmasked-/-0295_angle-left-' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Shape' fill='%23000000' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons / unmasked / 0290_angle-right %3C/title%3E%3Cdefs%3E%3Cpath d='M13.5857864,12 L8.29289322,17.2928932 C7.90236893,17.6834175 7.90236893,18.3165825 8.29289322,18.7071068 C8.68341751,19.0976311 9.31658249,19.0976311 9.70710678,18.7071068 L15.7071068,12.7071068 C16.0976311,12.3165825 16.0976311,11.6834175 15.7071068,11.2928932 L9.70710678,5.29289322 C9.31658249,4.90236893 8.68341751,4.90236893 8.29289322,5.29289322 C7.90236893,5.68341751 7.90236893,6.31658249 8.29289322,6.70710678 L13.5857864,12 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Icons-/-unmasked-/-0290_angle-right-' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Shape' fill='%23000000' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E") !default;
$carousel-control-color: $hv-gray-800 !default;
$carousel-indicator-active-bg: $hv-gray-800 !default;
