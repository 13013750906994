
// Modal changes
.modal-header {
  // override bootstrap-vue modal close button
  button.v-b-modal-close {
    margin: (-$modal-inner-padding-y) (-$modal-inner-padding-x) (-$modal-inner-padding-y) auto;
    white-space: nowrap;
    width: 3.8rem;
    overflow: hidden;
    text-indent: 3.8rem;
    &:before {
      text-indent: 0;
      float: left;
    }
  }
  .modal-title {
    font-size: $hv-modal-header-font-size
  }
}

.modal-body {
  min-height: $modal-inner-min-height;
}

.modal-footer {
  background-color: $light;
}

.modal-close {
  &.btn.icon.icon-xl {
    @extend .icon-xl;
  }
  &.btn-link {
    color: $dark;
  }
  &.btn-link:hover,
  &.btn-link:focus {
    text-decoration: none;
    color: $dark;
  }
}

.modal-lg {
  min-width: 95%;
  @include media-breakpoint-up(md) {
    min-width: 85%;
  }
  @include media-breakpoint-up(lg) {
    min-width: 55%;
  }
}

.modal-xl {
  min-width: 95%;
  @include media-breakpoint-up(md) {
    min-width: 85%;
  }
  @include media-breakpoint-up(lg) {
    min-width: 75%;
  }
  @include media-breakpoint-up(xl) {
    min-width: 65%;
  }
}

// Move the modal to center
.modal {
  text-align: center; // move to center
}

.modal::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; // move to vertically center
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

