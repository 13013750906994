/*
 * Tooltips
 *
 */

// AngularJS only, remove when migrated to Vue
.tooltip-questionmark {
	// Use this when the tooltip markup is direct sibling if the label element
	// it points to
	&.float-right {
		@include media-breakpoint-down(xs){
			position: absolute;
	    right: 0;
		}
	  +label, +.label{
	    padding-right: $hv-spacing-4;
	  }
	}
	// Use this when the tooltip markup is next to a form-field-input directive
	&.absolute-right {
		position: absolute;
		top: .25rem;
		right: 0;
		z-index: 9999;
	}
	// Goes inline text, next to where text ends (right side of the element)
	&.inline-text {
		margin-left: .7rem; // looks good
		@include media-breakpoint-only(sm){
			margin-left: .3rem;
		}
	}
	// Header tooltip, next to where text ends but in dark color
	&.header {
		position: relative;
		top: initial;
		margin-left: $hv-spacing-2;
		text-decoration: none;
		color: $gray-900;
	}
}

.icon[class^='tooltip'] {
	display: inline-block; // from _reboot
	// float-right means old-style tooltip questionmarks which we dont want blue yet
	// TODO: remove this eventually
	&:not(.float-right) {
		color: $link-color;
		font-size: $font-size-lg;
		margin-left: $hv-spacing-1;
		line-height: 0;
	}
	// just in case this is a link
	&:hover {
		text-decoration: none;
	}
}
