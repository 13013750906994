@use "sass:map";

/*
 * Holvi badge modifications and additions
 */

// additional badge style specifically for square badges
.badge-square {
  width: $badge-square-size;
  height: $badge-square-size;
  font-size: $hv-icon-font-size-lg;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: $badge-border-radius;
  padding: $hv-spacing-0_5;
}

@each $key, $value in $theme-colors {
  .badge-#{$key} {
    // add border to all badges so that outlined badges aren't bigger than others
    border: $badge-border-width solid $value;
  }
}

@each $key, $value in $holvi-colors {
  $baseMap: map.get($value, 'base');
  $darkMap: map.get($value, 'dark');
  $lightMap: map.get($value, 'light');

  // adjust these because we should use dark colour if we have it for accessibility contrast
  .badge-#{$key} {
    background-color: $lightMap;
    border-color: $lightMap;
    color: $darkMap;
  }
  // custom holvi badges, dark only for accessibility contrast
  .badge-outline-#{$key} {
    background-color: $white;
    color: $darkMap;
    border: $badge-border-width solid $darkMap;
  }
}

// Special "discount" badge:
.badge-discount {
  background-color: $hv-brand-primary-1;
  border-radius: $hv-border-radius-none;
}
